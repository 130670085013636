import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchDashboardData(tz) {
  console.log("tz: " + tz);
  return await apiHelper.makeApiCall(
    url.API_URL + url.ADMIN_DASHBOARD + `?tz=${encodeURIComponent(tz)}`,
    "GET",
    null
  );
}

export default {
  fetchDashboardData,
};
