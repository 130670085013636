import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function addQuestion(
  questionData = {
    questionText,
    yesScore,
    noScore,
    approvedConditionalIndicator,
    approvedConditionalText,
    treatmentIds,
  }
) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.ADD_QUESTION,
    "POST",
    questionData
  );
}

async function editQuestion(
  questionData = {
    questionText,
    yesScore,
    noScore,
    approvedConditionalIndicator,
    approvedConditionalText,
    treatmentIds,
  }
) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.EDIT_QUESTION,
    "PUT",
    questionData
  );
}

async function fetchQuestions() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_QUESTION,
    "GET",
    null
  );
}

async function filterQuestions(treatmentIds) {
  let apiUrl = url.API_URL + url.FETCH_QUESTION + "?";
  let append = "";
  for (let i = 0; i < treatmentIds.length; i++) {
    append += `treatmentid=${treatmentIds[i]}&`;
  }
  return await apiHelper.makeApiCall(apiUrl + append, "GET", null);
}

export default {
  addQuestion,
  editQuestion,
  fetchQuestions,
  filterQuestions,
};
