import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchProviderDashboardData() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.PROVIDER_DASHBOARD,
    "GET",
    null
  );
}

async function providerPresencePing() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.PROVIDER_PRESENCE_PING,
    "POST",
    null
  );
}

async function getCurrentOnlineProvidersCount() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.GET_ONLINE_PROVIDERS_COUNT,
    "GET",
    null
  );
}

export default {
  fetchProviderDashboardData,
  providerPresencePing,
  getCurrentOnlineProvidersCount,
};
