import gfeService from "../Services/gfe.service";
import cookie from "../helper/cookieHelpers";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async notifyCallEnd({ commit }, { gfeId, endedBy }) {
    const response = await gfeService.notifyCallEnd({
      gfeId,
      endedBy,
    });
    return response;
  },

  async fetchGfeSummary(
    { commit },
    {
      providerId = null,
      patientId = null,
      customerId = null,
      fromCreatedAt = null,
      toCreatedAt = null,
      fromCompletedAt = null,
      toCompletedAt = null,
      fromStartedAt = null,
      toStartedAt = null,
      status = null,
    }
  ) {
    const response = await gfeService.fetchGfeSummary(
      providerId,
      patientId,
      customerId,
      fromCreatedAt,
      toCreatedAt,
      fromCompletedAt,
      toCompletedAt,
      fromStartedAt,
      toStartedAt,
      status
    );
    return response;
  },

  async fetchMonthlyGfeTrend(
    { commit },
    { customerId = null, statuses = [], tz = null }
  ) {
    const response = await gfeService.fetchMonthlyGfeTrend(
      customerId,
      statuses,
      tz
    );
    return response;
  },

  async fetchInProgressGfes({ commit }) {
    const response = await gfeService.fetchInProgressGfes();
    return response;
  },

  async fetchGFEsByLocationId({ commit }, { locationId, customerId }) {
    const response = await gfeService.fetchGFEsByLocationId({
      locationId,
      customerId,
    });
    return response;
  },

  async updateGfe({ commit }, data) {
    const response = await gfeService.updateGfe(data);
    return response;
  },

  async startGfeCallRecording({ commit }, data) {
    const response = await gfeService.startGfeCallRecording(data);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
