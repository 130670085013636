import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchPatientsOnlineCount() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_PATIENTS_ONLINE_COUNT,
    "GET",
    null
  );
}

export default {
    fetchPatientsOnlineCount,
};
