import advertisementService from "../Services/advertisement.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchAdvertisement({ commit }) {
    const response = await advertisementService.fetchAdvertisement();
    return response;
  },
  async createAdvertisement({ commit }, advertisement ) {
    const response = await advertisementService.creatAdvertisement(advertisement);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
