import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import CKEditor from "@ckeditor/ckeditor5-vue";

//const lang = localStorage.getItem("lang") || "en";

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueTelInput)
  // .use(VeeValidate)
  .use(CKEditor)
  .mount("#app");
