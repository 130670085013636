import providerService from "../Services/provider.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchProviderDashboardData({ commit }) {
    const response = await providerService.fetchProviderDashboardData();
    return response;
  },

  async providerPresencePing({ commit }) {
    const response = await providerService.providerPresencePing();
    return response;
  },

  async getCurrentOnlineProvidersCount({ commit }) {
    const response = await providerService.getCurrentOnlineProvidersCount();
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
