const state = {
  isMenuCollapsed: null,
};

const getters = {
  isMenuCollapsed: (state) => state.isMenuCollapsed,
};

const actions = {
  checkIfMenuIsCollapsed({ commit }, isMenuCollapsed) {
    commit("setMenuCollapseValue", isMenuCollapsed);
  },
};

const mutations = {
  setMenuCollapseValue: (state, flag) => {
    state.isMenuCollapsed = flag;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
