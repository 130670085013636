import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchTotalCustomerCount() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_TOTAL_CUSTOMER_COUNT + "?status=approved",
    "GET",
    null
  );
}

export default {
  fetchTotalCustomerCount,
};
