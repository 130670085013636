import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchInvoice() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_INVOICE,
    "GET",
    null
  );
}

async function CreateInvoice(
  invoiceSettingsData = {
    subscriptionType: "detailed",
    customerId,
    gracePeriod,
    subscriptionFee,
  }
) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.CREATE_INVOICE,
    "POST",
    invoiceSettingsData
  );
}

async function fetchInvoiceById(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_INVOICE_BY_Id + "/" + id,
    "GET"
  );
}

async function markInvoicePayment(data) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.MARK_INVOICE_PAYMENT,
    "POST",
    data
  );
}

async function makeInvoicePayment(invoiceId) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.MAKE_INVOICE_PAYMENT,
    "POST",
    invoiceId
  );
}

export default {
  fetchInvoice,
  CreateInvoice,
  fetchInvoiceById,
  markInvoicePayment,
  makeInvoicePayment,
};
