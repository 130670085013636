import customerService from "../Services/customer.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchTotalCustomerCount({ commit }) {
    const response = await customerService.fetchTotalCustomerCount();
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
