import patientService from "../Services/patient.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchPatientsOnlineCount({ commit }) {
    const response = await patientService.fetchPatientsOnlineCount();
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
