import adminService from "../Services/admin.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchDashboardData({ commit }, tz) {
    const response = await adminService.fetchDashboardData(tz);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
