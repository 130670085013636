import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchAdvertisement() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_ADVERTISEMENT,
    "GET",
    null
  );
}
async function creatAdvertisement(data) {
    return await apiHelper.makeApiCall(
        url.API_URL + url.CREATE_ADVERTISEMENT,
        "POST",
        data
    );
}
export default {
    fetchAdvertisement,
    creatAdvertisement
};
